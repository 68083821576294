import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import PropTypes from "prop-types";
import React from "react";

import styles from "./FormattedHeadline.module.scss";

const FormattedHeadline = ({ richText, multiLine }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => `${next(node.content)}`,
    },
  };

  let element;

  if (multiLine) {
    element = (
      <span className={styles.formattedTextMultiLine}>{multiLine}</span>
    );
  } else {
    element = (
      <span
        className={styles.formattedText}
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(richText, options),
        }}
      />
    );
  }

  return element;
};

FormattedHeadline.propTypes = {
  richText: PropTypes.object.isRequired,
};

export default FormattedHeadline;
